import React, { Component } from "react";

import Layout from '../components/Layout'
import Presentation from '../components/Presentation'
import TypeServices from '../components/TypeServices'
import Gallery from '../components/Gallery'
import Clients from '../components/Clients'
import ButtonMessage from '../components/ButtonMessage'
import PopUp from '../components/PopUp'

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOverlayOpen: false,
      popUp: false
    };
    this.overlayToggleClickHandler = this.overlayToggleClickHandler.bind(this);
    this.popUpToggleClickHandler = this.popUpToggleClickHandler.bind(this);
  }

  overlayToggleClickHandler = () => {
    this.setState((prevState) => ({
      menuOverlayOpen: !prevState.menuOverlayOpen
    }));
  };

  popUpToggleClickHandler = (e) => {
    e.preventDefault();
    this.overlayToggleClickHandler();
    this.setState((prevState) => ({
      popUp: !prevState.popUp
    }));
  };
  render() {
  return (
    <div>
      <Layout>
        <Presentation />
        <TypeServices />
        <Gallery />
        <Clients />
        <ButtonMessage />
      </Layout>
    </div>
    )
  }
}

export default IndexPage

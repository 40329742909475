import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import './styles.css'

const Presentation = () => {
  return (
      <div className="presentation">
        <div className="presentation-content">
          <div className="presentation-text">
            <h1 className="presentation-title">VOP, SEGURANÇA PARA SUA CASA E EMPRESA</h1>
            <p className="presentation-description">Entre em contato e faça um orçamento para saber melhor sobre nossos sistemas e opções de segurança!</p>
            <a href="#type-service" className="presentation-button">Conheça os serviços</a>
          </div>
          <div className="presentation-card-image">
            <StaticImage
              src="../../images/autorizada.png"
              alt="Logo Vop"
              layout="fullWidth"
              />
          </div>
        </div>
      </div>
  )
}

export default Presentation
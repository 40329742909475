import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Whatsapp } from "@styled-icons/boxicons-logos/Whatsapp";

import './styles.css'

const TypeServices = () => {
  /*const handleSubmit = (dado) => {

    let message = encodeURI(
      `Olá, gostaria de fazer um orçamento referente ao serviço de ${dado}`
    );

    window.location.replace(`https://wa.me/+5518997269642?text=${message}`);
  }*/

  return (
    <div id="type-service">
        <div className="services-text">
            <h1 className="services-title">Nossos Serviços</h1>
            <p className="services-description">Veja os serviços de segurança que prestamos.</p>
        </div>
        <section className="services-cards">
          <div className="services-card">
            <div className="services-card-image">
              <StaticImage
                src="../../images/service-desarmado.jpg"
                alt="Logo Vop"
                layout="fullWidth"
              />
            </div>
            <h3 className="services-card-title">Vigilância Desarmada</h3 >
            <p className="services-card-description">Visando prevenir a ocorrências de furtos, roubos e invasoes do seu patrimônio, presença fisica do vigilante utlizando equipamentos nao letais.</p >
            {/*<button onClick={() => handleSubmit("Vigilância Desarmada")}><Whatsapp />Faça orçamento agora</button>*/}
          </div>
          <div className="services-card">
            <div className="services-card-image">
              <StaticImage
                src="../../images/service-armado.jpg"
                alt="Logo Vop"
                layout="fullWidth"
              />
            </div>
            <h3 className="services-card-title">Vigilância Armada</h3 >
            <p className="services-card-description">A vigilância armada consiste no recrutamento dos melhores profissionais com a utilização de armas, para realizar a segurança de seu patrimonio.</p >
            {/*<button onClick={() => handleSubmit("Vigilância Armada")}><Whatsapp />Faça orçamento agora</button>*/}
          </div>
          <div className="services-card">
            <div className="services-card-image">
              <StaticImage
                src="../../images/service-evento.jpg"
                alt="Logo Vop"
                layout="fullWidth"
              />
            </div>
            <h3 className="services-card-title">Vigilantes em eventos</h3 >
            <p className="services-card-description">Vasta experiencia em eventos de grade e médio porte, visando garantir o bem estar e a segurança do publico.</p >
            {/*<button onClick={() => handleSubmit("Vigilantes em eventos")}><Whatsapp />Faça orçamento agora</button>*/}
          </div>
        </section>
    </div>
  )
}

export default TypeServices
import React, { Component } from "react";

import VopImg from '../../images/vop-seguranca.jpg'
import video_1 from "../../images/video-1.mp4";
import video_2 from "../../images/video-2.mp4";
import post_1 from "../../images/vop-1.png";
import post_2 from "../../images/vop-2.png";
import post_3 from "../../images/vop-3.png";
import post_4 from "../../images/vop-4.png";
import post_5 from "../../images/vop-5.png";
import post_6 from "../../images/vop-6.png";
import post_7 from "../../images/vop-7.jpg";
import post_8 from "../../images/vop-8.jpeg";

import "./styles.css";

class Gallery extends Component {
  render() {
    return (
      <div className="social" style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9)), url('${VopImg}')`,
      }}>
        <div className="social-section">
          <h1 className="title">Nossa Galeria</h1>
          <p>Algumas fotos e videos da nossa equipe e serviços.</p>
          <section>
            <div className="social-item">
              <div className="social-image">
                <video controls>
                  <source src={video_1} type="video/mp4" />
                </video>
              </div>
            </div>

            <div className="social-item">
              <div className="social-image">
                <video controls>
                  <source src={video_2} type="video/mp4" />
                </video>
              </div>
            </div>

            <div className="social-item">
                <div className="social-image">
                  <img src={post_1} alt="Post Instagram" />
                </div>
            </div>

            <div className="social-item">
                <div className="social-image">
                  <img src={post_2} alt="Post Instagram" />
                </div>
            </div>

            <div className="social-item">
                <div className="social-image">
                  <img src={post_3} alt="Post Instagram" />
                </div>
            </div>

            <div className="social-item">
                <div className="social-image">
                  <img src={post_4} alt="Post Instagram" />
                </div>
            </div>

            <div className="social-item">
                <div className="social-image">
                  <img src={post_5} alt="Post Instagram" />
                </div>
            </div>

            <div className="social-item">
                <div className="social-image">
                  <img src={post_6} alt="Post Instagram" />
                </div>
            </div>

            <div className="social-item">
                <div className="social-image">
                  <img src={post_7} alt="Post Instagram" />
                </div>
            </div>

            <div className="social-item">
                <div className="social-image">
                  <img src={post_8} alt="Post Instagram" />
                </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Gallery;
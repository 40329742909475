import React, { Component } from "react";

import cliente_1 from "../../images/cliente-1.png";
import cliente_2 from "../../images/cliente-2.png";
import cliente_3 from "../../images/cliente-3.png";
import cliente_4 from "../../images/cliente-4.png";
import cliente_5 from "../../images/cliente-5.png";
import cliente_6 from "../../images/cliente-6.png";
import cliente_7 from "../../images/cliente-7.png";
import cliente_8 from "../../images/cliente-8.png";
import cliente_9 from "../../images/cliente-9.png";
import cliente_10 from "../../images/cliente-10.png";
import cliente_11 from "../../images/cliente-11.png";
import cliente_12 from "../../images/cliente-12.png";
import cliente_13 from "../../images/cliente-13.png";
import cliente_14 from "../../images/cliente-14.jpg";


import "./styles.css";

class Clients extends Component {
  render() {
    return (
      <div className="clients">
        <div className="clients-section">
          <h1 className="title">Clientes</h1>
          <section>
            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_1} alt="Post Instagram" />
                </div>
            </div>

            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_2} alt="Post Instagram" />
                </div>
            </div>

            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_3} alt="Post Instagram" />
                </div>
            </div>

            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_4} alt="Post Instagram" />
                </div>
            </div>

            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_5} alt="Post Instagram" />
                </div>
            </div>

            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_6} alt="Post Instagram" />
                </div>
            </div>

            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_7} alt="Post Instagram" />
                </div>
            </div>

            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_8} alt="Post Instagram" />
                </div>
            </div>

            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_9} alt="Post Instagram" />
                </div>
            </div>

            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_10} alt="Post Instagram" />
                </div>
            </div>

            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_11} alt="Post Instagram" />
                </div>
            </div>

            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_12} alt="Post Instagram" />
                </div>
            </div>

            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_13} alt="Post Instagram" />
                </div>
            </div>

            <div className="clients-item">
                <div className="clients-image">
                  <img src={cliente_14} alt="Post Instagram" />
                </div>
            </div>

          </section>
        </div>
      </div>
    );
  }
}

export default Clients;
import React from "react";
import { Whatsapp } from "@styled-icons/boxicons-logos/Whatsapp";

import "./styles.css";
import { Link } from "gatsby";

const ButtonMessage = () => {
    return (
        <Link className="message" to="/formulario">
            <Whatsapp />
        </Link>
    );
};

export default ButtonMessage;